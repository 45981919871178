import React, { useEffect, useState } from "react"
import styled from "styled-components"
import {
  headlineOne,
  medWrapper,
  colors,
  bodyCopy,
  buttonTwo,
} from "../Utilities"

import LogoDarkBlue from "../elements/LogoDarkBlue"

const MailChimpPopup = () => {
  const [isActive, toggleActive] = useState(false)

  useEffect(() => {
    const alreadyClickClose = localStorage.getItem("popupWarn")
    if (alreadyClickClose) return
    setTimeout(() => {
      toggleActive(true)
    }, 1000)
  }, [])

  return (
    <MailChimpPopupStyled isActive={isActive} id="popupWarning">
      <div className="container">
        <div>
          <div className="mainPopupLogo">
            <LogoDarkBlue />
          </div>
          <h2>I Know A Guy Renovations</h2>

          <div className="container__content">
            <p>Join Us! Learn tips, tricks & see the latest trends.</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://eepurl.com/hzKVrv"
            >
              Join Our Mail List
            </a>
          </div>
          <div className="container__button">
            <button
              onClick={() => {
                toggleActive(false)
                localStorage.setItem("popupWarn", true)
              }}
            >
              &#x2715;
            </button>
          </div>
        </div>
      </div>
    </MailChimpPopupStyled>
  )
}

const MailChimpPopupStyled = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(157, 201, 228, 0.85);
  z-index: 999999999;
  transition: all 0.3 ease;
  opacity: ${props => (props.isActive ? 1 : 0)};
  visibility: ${props => (props.isActive ? "visable" : "hidden")};

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 70rem;
    padding: 4rem;
    margin: auto;
    background-color: rgba(255, 255, 255, 1);
    border: 1.5rem solid #e66544;
    transform: translate(-50%, -50%);

    .mainPopupLogo {
      max-width: 20rem;
      margin: 0 auto 2rem;
    }

    h2 {
      ${headlineOne};
      color: #9dc9e4;
      text-align: center;
    }

    &__content {
      text-align: center;
      margin-bottom: 5rem;

      p {
        ${bodyCopy};

        span {
          display: inline-block;
          width: 2.5rem;
          transform: translateY(1.25rem);
        }
      }

      a {
        ${buttonTwo};
      }
    }

    &__button {
      position: absolute;
      top: 2rem;
      right: 2rem;

      button {
        width: 4rem;
        height: 4rem;
        background-color: transparent;
        border-radius: 50%;
        transition: 0.3s ease-in all;
        cursor: pointer;

        &:hover {
          background-color: #e04017;
          color: #fff;
        }
      }
    }
  }
`

export default MailChimpPopup
