import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import SocialFollow from "./SocialFollow"
import styled from "styled-components"
import BGImg from "gatsby-background-image"

import {
  headlineOne,
  medWrapper,
  colors,
  bodyCopy,
  buttonTwo,
} from "../Utilities"

const FooterStyled = styled.footer`
  position: relative;
  .wrapper {
    ${medWrapper};
  }

  .footerSocial {
    width: calc(100%);
    margin-bottom: 5rem;

    @media (min-width: 768px) {
      width: calc(70% - 10rem);
      margin-right: 10rem;
      margin-bottom: 0;
    }

    &__quote {
      h3 {
        ${headlineOne};
        color: ${colors.colorPrimary};
        text-align: center;

        @media (min-width: 768px) {
          text-align: left;
        }

        span {
          @media (min-width: 768px) {
            display: block;
          }
        }

        span:last-of-type {
          font-style: italic;
        }
      }
    }

    &__icons {
      .wrapper {
        justify-content: center;
        padding-right: 0;
        padding-left: 0;

        @media (min-width: 768px) {
          justify-content: flex-start;
          padding-right: 2rem;
        }

        div {
          margin-right: 0.75rem;
          @media (min-width: 768px) {
            margin-right: 2rem;
          }

          a {
            display: block;
            border-radius: 50%;
            border: solid 0.2rem ${colors.grey};
            width: 3.25rem;
            height: 3.25rem;
            padding: 0.5rem;
            color: ${colors.grey};

            @media (min-width: 375px) {
              width: 4rem;
              height: 4rem;
              padding: 0.7rem;
            }

            @media (min-width: 768px) {
              width: 4.5rem;
              height: 4.5rem;
            }

            &:hover {
              border: solid 0.2rem ${colors.white};
              color: ${colors.white};
            }
          }
        }
      }

      .mailchimp-link {
        width: 100%;
        margin-top: 2.5rem;
        a {
          ${buttonTwo};
          text-align: center;

          @media (min-width: 768px) {
            text-align: left;
          }
        }
      }
    }
  }

  .footerBtn {
    align-self: center;
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(30%);
    }

    &__title {
      h3 {
        ${headlineOne};
        margin: 0;
        color: ${colors.colorPrimary};
      }

      p {
        ${bodyCopy};
        color: ${colors.grey};
      }
    }

    &__link {
      a {
        ${buttonTwo};
      }
    }
  }

  .footerCopy {
    width: 100%;
    padding-top: 7.5rem;
    text-align: center;

    p {
      ${bodyCopy};
      color: ${colors.grey};

      a {
        ${bodyCopy};
        color: ${colors.grey};
      }
    }
  }

  .footerBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      position: static !important;
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;
    }
  }
`

const getData = graphql`
  query footerOptions {
    footData: wordpressAcfOptions {
      options {
        ikg_footer_bgi {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        ikg_footer_quote_top
        ikg_footer_quote_bottom
        ikg_footer_social_media {
          social_url
          social_type
        }
        ikg_switchback_credit
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(getData)
  const {
    footData: { options },
  } = data

  const icons = options.ikg_footer_social_media
  const quoteTop = options.ikg_footer_quote_top
  const quoteBot = options.ikg_footer_quote_bottom
  const bgImageFluid = options.ikg_footer_bgi.localFile.childImageSharp.fluid
  const swbCred = options.ikg_switchback_credit

  return (
    <FooterStyled>
      <div className="wrapper">
        <div className="footerSocial">
          <div className="footerSocial__quote">
            <h3>
              <span>{quoteTop} </span>
              <span>{quoteBot}</span>
            </h3>
          </div>
          <div className="footerSocial__icons">
            <SocialFollow icons={icons} />
            <div className="mailchimp-link">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://eepurl.com/hzKVrv"
              >
                Join Us! Learn tips, tricks & see the latest trends.
              </a>
            </div>
          </div>
        </div>
        <div className="footerBtn">
          <div className="footerBtn__title">
            <h3>Get Started</h3>
            <p>Request a consultation</p>
          </div>
          <div className="footerBtn__link">
            <Link to="/contact">Contact us today</Link>
          </div>
        </div>
        <div className="footerCopy">
          <p>
            <Link to="/privacy-policy">Privacy Policy</Link>
            {" | "}
            <Link to="/disclaimer">Disclaimer</Link>
          </p>
          <p>
            Copyright © {new Date().getFullYear()} I Know A Guy Renovations.
            Designed, developed and built by{" "}
            {swbCred === "yes" && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://switchbackcreative.ca"
              >
                Switchback Creative
              </a>
            )}
            . Built with ♡ and{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.gatsbyjs.org"
            >
              Gatsby
            </a>
            .
          </p>
        </div>
      </div>
      <div className="footerBackground">
        <BGImg Tag="div" fluid={bgImageFluid} />
      </div>
    </FooterStyled>
  )
}

export default Footer
