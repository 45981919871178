export const colorPrimary = "#143554"
export const colorSecondary = "#9DC9E4"
export const colorTertiary = "#E04017"
export const colorAccent = "#E66544"
export const grey = "#65646A"
export const ironGrey = "#CDD1D2"
export const white = "#fff"
export const black = "#000"
export const strongRed = "#f00"

export default {
  colorPrimary,
  colorSecondary,
  colorTertiary,
  colorAccent,
  grey,
  ironGrey,
  white,
  black,
  strongRed,
}
