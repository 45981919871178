const theme = {
  colorPrim: "#143554",
  colorSec: "#9DC9E4",
  colorTert: "#E04017",
  colorAcc: "#E66544",
  white: "#fff",
  grey: "#65646A",
  ironGrey: "#CDD1D2",
  black: "#000",
  strongred: "#f00",
  maxWidth: "1000px",
  baseLineHeight: "1.5",
  bpTablet: "768px",
  bpDesksm: "1025px",
  bpDeskmd: "1200px",
  bpDesklg: "1600px",
  bpMax: "1900px",
  fontPrim: "Montserrat",
  fontSec: "Lora",
  fontAwesome: "FontAwesome",
}

export default theme
